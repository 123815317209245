<template>
  <div>
    <div class="title">平台服务</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select">搜索</div>
      </div>
    </div>

    <el-tabs
      style="margin-top: 20px;"
      v-model="activeName"
      @tab-click="handleClick"
    > 
      <el-tab-pane
        v-for="item in panes"
        :key="item.id"
        :label="item.name"
        :name="item.name"
      >
        <div class="goods_list">
          <div class="goods_item" v-for="item in tableData" :key="item.id" @click="applyService(item.id)">
            <div class="goods_item_head">
              <div class="goods_item_head_title">{{item.name}}</div>
              <div class="goods_item_head_price">
                <span>¥</span>
                <span>{{item.price}}</span>
                <span> /{{item.unit}}</span>
              </div>
            </div>
            <div class="goods_item_content">{{item.desc}}</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      activeName: "推荐服务",
      panes: [],
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      search: {
        keyword: "",
      },
      tableData: [],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.getPlatformServicesCate()
      .then((res) => {
        console.log(res);
        this.panes = res.data.data.list;
        // 加载第一个分类
        if (this.panes[0]) {
          this.activeName = this.panes[0].name;
          this.loadData((loadDataObj) => {
            loadDataObj.cate_id = this.panes[0].id;
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    ...mapActions("flexibleEmployment", [
      "getPlatformServicesCate",
      "getPlatformServicesList",
      "getServiceApply"
    ]),

    applyService(id) {
      this.$confirm("是否申请此服务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.getServiceApply({ id })
            .then((res) => {
              console.log(res);
              this.$message.success(res.data.msg);
            })
            .catch((e) => {
              console.log(e);
              this.$message.error(e.data?.msg || "申请服务失败");
            });
        })
    },

    loadData(func) {
      // 单例初始化
      if (!this.loadDataObj)
        this.loadDataObj = new LoadDataClass(0, this.getPlatformServicesList);

      // 通知，用于修改对象属性
      func(this.loadDataObj);

      let loading = this.$loading();
      this.loadDataObj
        .load(this.page.page)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      this.loadData((loadDataObj) => {
        loadDataObj.keyword = this.search.keyword;
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
      this.page.page = 1;
      this.loadData((loadDataObj) => {
        loadDataObj.cate_id = this.panes[tab.index].id;
      });
    },
  },
};

class LoadDataClass {
  constructor(cate_id, request, keyword = null) {
    this.cate_id = cate_id;
    this.func = request;
    this.keyword = keyword;
  }

  load(page) {
    let params = {
      cate_id: this.cate_id,
    };
    if (this.keyword) params.keywords = this.keyword;
    params.page = page;
    return this.func(params);
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 680px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.el-tabs {
  ::v-deep .el-tabs__header {
    margin: 0;
  }

  ::v-deep .el-tabs__item {
    font-size: 16px;
    color: #495060;
    height: 45px;

    &.is-active {
      color: #4db9d5;
    }
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #4db9d5;
  }
}

.goods_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;

  .goods_item {
    width: calc(50% - 82px);
    background: #ffffff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 40px 35px;

    .goods_item_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 3px;
        background: #4db9d5;
      }

      .goods_item_head_title {
        font-size: 20px;
        color: #1c2438;
      }

      .goods_item_head_price {
        > span:nth-child(1) {
          font-size: 14px;
          color: #f94d09;
        }

        > span:nth-child(2) {
          font-size: 26px;
          color: #f94d09;
        }

        > span:nth-child(3) {
          font-size: 14px;
          color: #1c2438;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }

  .goods_item_content {
    font-size: 14px;
    color: #1c2438;
    line-height: 30px;
    margin-top: 50px;
  }
}
</style>
